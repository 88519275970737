@keyframes turn {
	0% {
		transform: rotateZ(0deg);
		left: 57.12vw;
	}
	100% {
		transform: rotateZ(-360deg);
		left: 10vw;
	}
}
@keyframes slide {
	0% {
		left: 49.12vw;
	}
	100% {
		left: 2vw;
	}
}
@keyframes extend {
	0% {
		width: 25vw;
		left: 64.62vw;
	}
	100% {
		width: 72.12vw;
		left: 17.5vw;
	}
}
@keyframes fade {
	0% {
		opacity: 1;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.tr-background {
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh) * 100);
	position: fixed;
	top: 0;
	background-color: rgb(240, 206, 152);
	animation-name: fade;
	animation-duration: 2s;
	animation-delay: 1.7s;
	animation-fill-mode: forwards;
	z-index: 1000;
}

.tr-cont {
	display: flex;
	height: 100vh;
	height: calc(var(--vh) * 100);
	width: 100vw;
	justify-content: center;
	align-items: center;
}
.tr-img {
	width: 70vw;
}
.tr-wheel {
	position: relative;
	width: 15vw;
	animation-name: turn;
	animation-duration: 1.8s;
	left: 10vw;
}
.tr-sled {
	position: relative;
	width: 35.75vw;
	left: 2vw;
	animation-name: slide;
	animation-duration: 1.8s;
}
.tr-line {
	position: relative;
	width: 72.12vw;
	left: 17.5vw;
	bottom: 8px;
	border-bottom: solid rgb(41, 41, 41) 4px;
	animation-name: extend;
	animation-duration: 1.8s;
}
.load {
	animation-delay: 0.8s;
}
.load-img {
	width: 4rem;
	animation: spin 0.3s linear 0s infinite;
}

@media (orientation: landscape) {
	.tr-img {
		width: 35vw;
	}
	.tr-wheel {
		width: 7.5vw;
		left: 5vw;
	}
	.tr-sled {
		width: 17.875vw;
		left: 1vw;
	}
	.tr-line {
		width: 72.12vw;
		left: 8.75vw;
		border-bottom: solid rgb(41, 41, 41) 0.3vw;
	}
	@keyframes turn {
		0% {
			transform: rotateZ(0deg);
			left: 28.56vw;
		}
		100% {
			transform: rotateZ(-360deg);
			left: 5vw;
		}
	}
	@keyframes slide {
		0% {
			left: 24.56vw;
		}
		100% {
			left: 1vw;
		}
	}
	@keyframes extend {
		0% {
			width: 50vw;
			left: 32.31vw;
		}
		100% {
			width: 72.12vw;
			left: 8.75vw;
		}
	}
}

@keyframes end {
	0% {
		opacity: 1;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes endfade {
	0% {
		opacity: 1;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

.end-background {
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh) * 100);
	position: fixed;
	z-index: 4;
	top: 0;
	background-color: black;
	animation-name: end;
	animation-fill-mode: forwards;
	animation-duration: 3s;
	opacity: 0;
}
.end-back {
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh) * 100);
	position: fixed;
	z-index: 3;
	top: 0;
	background-image: radial-gradient(
		circle at 75% 50%,
		rgb(213, 244, 255) 0%,
		rgb(153, 228, 255) 55%,
		rgb(91, 212, 255) 100%
	);
	animation-name: endfade;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	animation-delay: 3s;
}
.end-credit {
	margin-left: 20vw;
	font-size: 1rem;
	color: white;
}
.end-thank {
	margin-left: 20vw;
	margin-top: 45vh;
	margin-top: calc(var(--vh) * 45);
	font-size: 1.4rem;
	font-weight: bold;
	color: white;
}
