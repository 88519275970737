.si-title {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    margin-bottom: 12px;
}

.si-cont {
    margin-bottom: 2rem;
}
.si-contact {
    display: flex;
    flex-direction: row;
    margin: 8px 0; 
    align-items: center;
}
.si-contact__text {
    margin-left: 8px;
    transition: text-decoration ease-in-out 0.2s;
    color: black;
}
.si-contact__text:hover, .si-contact__text:active {
    text-decoration: underline;
}

.si-download {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: solid black 2px;
    width:fit-content;
    padding: 2px 8px;
    border-radius: 1.2rem;
    margin-bottom: 8px;
    transition: background-color 0.2s;
}
.si-download:hover {
    background-color: black;
}
.si-download__text {
    margin-left: 8px;
    font-weight: 600;
    color: black;
}
.si-download__logo {
    height: 1.5rem;
}

.si-logo {
    width: 8rem;
}
.si-copyright {
    margin-top: 0.4rem;
    font-size: 0.8rem;
}

@media (orientation: portrait) {
    .si-logo {
        margin: 0 calc( 50% - 4rem );
    }
    .si-copyright {
        text-align: center;
    }
}